import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Cadastro(props) {
  const [errorMessage, setErrorMessage] = useState("");
  const handleCadastro = (event) => {
    event.preventDefault();
    
    toast.error("Infelizmente não foi possível fazer seu cadastro no momento. Tente novamente mais tarde.");
    
  };
  return (
    <div className="border-2 pt-4 mt-3 mt-md-0">
      <ToastContainer />
      <h2 className="font-bold text-xl text-red-600 h4 mb-3">Ainda não é nosso cliente?</h2>
      <div className="fs-sm mb-4">
        <div className="text-muted text-lg">
          Preencha os dados abaixo e faça o seu cadastro.
        </div>
        <div className="text-green-600 text-lg">
          Vendas somente para pessoa jurídica:{" "}
          <strong>distribuidores</strong> e <strong>atacados</strong>.
        </div>
      </div>
      <form noValidate id="form-cadastro" className="flex-end grid grid-cols-1 sm:grid-cols-2 gap-4" action="#">
        <div className="col-span-1">
          <div className="form-group">
            <label className="p-4 ml-4 form-group mt-3">CNPJ: </label>
            <input
              type="text"
              id="nr_cnpj_cpf"
              name="nr_cnpj_cpf"
              required
              className="form-control mt-1 rounded-md px-8 py-2 border border-gray-300"
              placeholder="Insira seu CNPJ"
            />
          </div>
          <div className="form-group">
            <label className="p-4 ml-4 form-label-cadastro">IE: </label>
            <input
              type="text"
              className="form-control mt-1 p-4 ml-6  rounded-md px-8 py-2 border border-gray-300"
              placeholder="Insira seu IE"
              id="nr_ie"
              name="nr_ie"
            />
          </div>
          <div className="form-group">
            <label className="ml-px mt-1 rounded-md  form-label-cadastro"> Razão Social: </label>
            <input
              type="text"
              className="form-control w-60 mt-1 rounded-md px-4 py-2 border border-gray-300"
              placeholder="Insira sua Razão Social"
              id="nm_pessoa"
              name="nm_pessoa"
              required
            />
          </div>
          <div className="form-group">
            <label className="ml-6 form-label-cadastro">E-mail:</label>
            <input
              type="email"
              className=" p-4 ml-6 w-60 form-control mt-1 rounded-md px-4 py-2 border border-gray-300"
              placeholder="Insira seu E-mail"
              id="ds_email"
              name="ds_email"
              required
            />
          </div>
          <div className="form-group">
            <label className="p-1.5 ml-4 form-label-cadastro">Telefone: </label>
            <input
              type="tel"
              className="w-60 p-px ml-px form-control mt-1 rounded-md px-4 py-2 border border-gray-300"
              placeholder="Insira seu Telefone"
              id="nr_telefone"
              name="nr_telefone"
              required
              autoComplete="off"
            />
          </div>
          <div className="form-group">
            <label className="ml-6 form-label-cadastro">Celular: </label>
            <input
              type="tel"
              className=" p-1 ml-4 w-60 form-control mt-1 rounded-md px-4 py-2 border border-gray-300"
              placeholder="Insira seu Celular"
              id="nr_celular"
              name="nr_celular"
              autoComplete="off"
            />
          </div>
          <div className="form-group">
            <label className="ml-6 form-label-cadastro">Contato: </label>
            <input
              type="text"
              className="p-1 ml-2.5 w-60 form-control mt-1 rounded-md px-4 py-2 border border-gray-300"
              placeholder="Insira seu Contato"
              id="nm_contato"
              name="nm_contato"
              required
            />
          </div>
          <div className="form-group">
            <label className="p-4 form-label-cadastro">Tipo de Regime</label>
            <select
              id="fl_opt_simples"
              name="fl_opt_simples"
              className="form-control mt-1 rounded-md px-4 py-2 border border-gray-300"
              required
            >
              <option value=""> --- Selecione ---</option>
              <option value="false">Regime Normal</option>
              <option value="true">Simples Nacional</option>
            </select>
          </div>
        </div>
        <div className="col-span-1">
          <div className="form-group">
            <label className="ml-6 form-label-cadastro">CEP: </label>
            <input
              type="text"
              className="p-2 ml-4 form-control mt-1 rounded-md px-4 py-2 border border-gray-300"
              placeholder="Insira seu CEP"
              id="nr_cep"
              name="nr_cep"
              required
              autoComplete="off"
            />
          </div>
          <div className="form-group">
            <label className="ml-6  form-label-cadastro">Rua: </label>
            <input
              type="text"
              className="p-2 ml-5 form-control mt-1 rounded-md px-4 py-2 border border-gray-300"
              placeholder="Insira sua Rua"
              id="nm_logradouro"
              name="nm_logradouro"
              required
            />
          </div>
          <div className="form-group">
            <label className="ml-4 form-label-cadastro">Número: </label>
            <input
              type="text"
              className="p-px ml-1 form-control mt-1 rounded-md px-4 py-2 border border-gray-300"
              placeholder="Insira o número"
              id="nr_endereco"
              name="nr_endereco"
              required
            />
          </div>
          <div className="form-group">
            <label className="-ml-4 mt-1 form-label-cadastro">Complemento: </label>
            <input
              type="text"
              className=" p-px m-mx form-control mt-1 rounded-md px-4 py-2 border border-gray-300"
              placeholder="Insira o complemento"
              id="nm_complemento"
              name="nm_complemento"
            />
          </div>
          <div className="form-group">
            <label className="p-4 form-label-cadastro">Bairro: </label>
            <input
              type="text"
              className="form-control mt-1 rounded-md px-4 py-2 border border-gray-300"
              placeholder="Insira seu Bairro"
              id="nm_bairro"
              name="nm_bairro"
              required
            />
          </div>
          <div className="form-group">
            <label className="ml-6 form-label-cadastro">Cidade: </label>
            <input
              type="text"
              className="form-control mt-1 rounded-md px-4 py-2 border border-gray-300"
              placeholder="Insira sua Cidade"
              id="nm_cidade"
              name="nm_cidade"
              required
            />
          </div>
          <div className="form-group">
            <label className="p-4 form-label-cadastro">Estado: </label>
            <select
              id="nm_estado"
              name="nm_estado"
              className="form-control mt-1 rounded-md px-4 py-2 border border-gray-300"
              required
            >
              <option value=""> --- Selecione ---</option>
              <option value="AC">Acre</option>
              <option value="AL">Alagoas</option>
              <option value="AP">Amapá</option>
              <option value="AM">Amazonas</option>
              <option value="BA">Bahia</option>
              <option value="CE">Ceará</option>
              <option value="DF">Distrito Federal</option>
              <option value="ES">Espírito Santo</option>
              <option value="GO">Goiás</option>
              <option value="MA">Maranhão</option>
              <option value="MT">Mato Grosso</option>
              <option value="MS">Mato Grosso do Sul</option>
              <option value="MG">Minas Gerais</option>
              <option value="PA">Pará</option>
              <option value="PB">Paraíba</option>
              <option value="PR">Paraná</option>
              <option value="PE">Pernambuco</option>
              <option value="PI">Piauí</option>
              <option value="RJ">Rio de Janeiro</option>
              <option value="RN">Rio Grande do Norte</option>
              <option value="RS">Rio Grande do Sul</option>
              <option value="RO">Rondônia</option>
              <option value="RR">Roraima</option>
              <option value="SC">Santa Catarina</option>
              <option value="SP">São Paulo</option>
              <option value="SE">Sergipe</option>
              <option value="TO">Tocantins</option>
            </select>
          </div>
        </div>
       
        <div className="flex gap-2 mt-3">
          <button
            type="submit"
            className="btn btn-primary bg-orange-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded"
            onClick={handleCadastro}
          >
            Cadastrar
          </button>
          <a className="btn btn-primary bg-orange-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded" href="/Login">
            Voltar
          </a>
          {errorMessage && (
            <div className="mt-4 text-red-600">{errorMessage}</div>
          )}
        </div>
      </form>
    </div>
  );
}
