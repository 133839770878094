import React from 'react';
// import data
import { features } from '../data';

const Feature4 = () => {
  // destructure features
  const { feature4 } = features;
  // destructure feature4
  const { title, subtitle, image } = feature4;
  return (
    <section className='section border-2'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row lg:items-center lg:gap-x-[30px]'>
          {/* image */}
          <div
            className='rounded md:rounded-lg flex-1 order-2 lg:order-1'
            data-aos='fade-right'
            data-aos-offset='200'
          >
            <img className="w-full rounded-lg md:rounded-lg" src={image} alt='' />
          </div>
          {/* text */}
          <div
            className='flex-1 order-1 lg:order-2'
            data-aos='fade-left'
            data-aos-offset='200'
          >
            <h2 className='title'>{title}</h2>
            <p className='lead'>{subtitle}</p>
            
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature4;
