import React from 'react';
// import data
import { footer } from '../data';
// import components
import Copyright from '../components/Copyright';
import logofooter from '../assets/img/header/logo-colorido.svg';

const Footer = () => {
  // destructure footer data
  const { links, atendimento, newsletter, form } = footer;
  return (
    <footer className='bg-white rounded-lg shadow dark:bg-gray-100 m-4'>
      <div className=' justify-center w-full max-w-screen-xl mx-auto p-4 md:py-8 container mx-auto'>
        <div className='sm:flex sm:items-center sm:justify-between flex flex-col items-center text-center lg:flex-row lg:items-start lg:text-left lg:justify-between gap-y-8'>
          {/* logo */}
          <div data-aos='fade-up' data-aos-offset='200' data-aos-delay='300'>
            <img className="w-[300px] h-auto justifify-self-auto p-2.5 ml-4 " src={logofooter} alt='logo' />
            
            
          </div>
          {/* list 1 */}
          <div data-aos='fade-up' data-aos-offset='200' data-aos-delay='500'>
            <div className='text-2xl uppercase font-medium mb-6 text-orange-600'>Links</div>
            <ul className='flex flex-col gap-y-3'>
              {links.map((item, index) => {
                // destructure item
                const { href, name } = item;
                return (
                  <li key={index}>
                    <a
                      className=' text-black font-medium hover:text-accent transition'
                      href={href}
                    >
                      {name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          {/* list 2 */}
          <div data-aos='fade-up' data-aos-offset='200' data-aos-delay='700'>
            <div className='text-2xl uppercase font-medium mb-6 text-orange-600'>Atendimento</div>
            <ul className='flex flex-col gap-y-3'>
              {atendimento.map((item, index) => {
                // destructure item
                const { href, name } = item;
                return (
                  <li key={index}>
                    <a
                      className='font-medium  transition'
                      href={href}
                    >
                      {name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          {/* newsletter */}
          <div data-aos='fade-up' data-aos-offset='200' data-aos-delay='900'>
            <div className='text-2xl uppercase font-medium mb-6'>
              {newsletter.title}
            </div>
            <div className='text-xl text-light mb-[18px]'>
              {newsletter.subtitle}
            </div>
            {/* form */}
            
            <span className='text-sm text-light'>{form.smallText}</span>
          </div>
        </div>
        <hr
          className='mt-10 mb-5'
          data-aos='fade-up'
          data-aos-offset='100'
          data-aos-delay='200'
        />
        <Copyright />
      </div>
    </footer>
  );
};

export default Footer;
