// Header.js

import React, { useState, useEffect } from "react";
import { header } from "../data";
import { HiMenuAlt4, HiOutlineX } from "react-icons/hi";
import MobileNav from "../components/MobileNav";
import Nav from "../components/Nav";
import Login from "../components/Login";

const Header = () => {
  const [mobileNav, setMobileNav] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const { logo, btnText } = header;

  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 60 ? setIsActive(true) : setIsActive(false);
    });
  });

  return (
    <header
      className={`${isActive ? "lg:top-0 bg-white shadow-2xl" : "lg:top-[60px]"
        } py-6 lg:py-4 fixed w-full transition-all z-10`}
    >
      <div className="container mx-auto flex justify-between items-center">
        <a href="#about" data-aos="fade-down" data-aos-delay="1500">
          <img className="w-[150px] h-auto" src={logo} alt="AOC Imports" />
        </a>
        <a href="/Login" data-aos="fade-up" data-aos-delay="1500">
          {/* <h3 className='font-bold'><em><bold>{Logo}</bold></em></h3> */}
        </a>

        <div
          className="hidden lg:flex"
          data-aos="fade-down"
          data-aos-delay="1200"
        >
          <Nav />
        </div>
        <a href="/Login" className=" lg:flex">
          <button
            className="btn btn-sm btn-outline"
            data-aos="fade-down"
            data-aos-delay="1400"
          >
            Area do Cliente
          </button>
        </a>
        <button className="lg:hidden" onClick={() => setMobileNav(!mobileNav)}>
          {mobileNav ? (
            <HiOutlineX className="text-3xl text-accent" />
          ) : (
            <HiMenuAlt4 className="text-3xl text-accent" />
          )}
        </button>
        <div
          className={`${mobileNav ? "left-0" : "-left-full"
            }  fixed top-0 bottom-0 w-[60vw] lg:hidden transition-all`}
        >
          <MobileNav setMobileNav={setMobileNav}>
            <a href="/Login">
              <button className="btn btn-sm btn-outline">Area do cliente</button>
            </a>
          </MobileNav>
        </div>
      </div>
    </header>
  );
};

export default Header;
