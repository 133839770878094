import React from "react";
// import data
import { cta } from "../data";
// import icons

const Cta = () => {
  // destructure cta data
  const { title, subtitle, img1, img2, img3, img4, img5 } = cta;
  return (
    <section className="section bg-cta bg-cover bg-left-top" id="cta">
      <div className=" max-w-[1340px] mx-auto px-[25px]">
        {/* text */}
        <div className="max-w-[920px] mx-auto text-center">
          <h2
            className="h2 text-white mb-6"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            {title}
          </h2>
          <p
            className="text-2xl lg:text-4xl text-white"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            {subtitle}
          </p>
        </div>
        {/* btn & images */}
        <div className="container mx-auto flex justify-evenly">
          <img
            className="w-24 h-24 md:w-48 md:h-auto md:rounded-none rounded-full mx-auto"
            src={img1}
            alt="ANVISA"
            data-aos="zoom-out-right"
            data-aos-delay="200"
          />
          <img
            className="w-24 h-24 md:w-48 md:h-auto md:rounded-none rounded-full mx-auto"
            src={img2}
            alt="CIAL"
            data-aos="zoom-out-left"
            data-aos-delay="200"
          />
          <img
            className="w-24 h-24 md:w-48 md:h-auto md:rounded-none rounded-full mx-auto"
            src={img3}
            alt="IBAMA"
            data-aos="zoom-out-right"
            data-aos-delay="200"
          />
          <img
            className="w-24 h-24 md:w-48 md:h-auto md:rounded-none rounded-full mx-auto"
            src={img4}
            alt="INMETRO"
            data-aos="zoom-out-right"
            data-aos-delay="200"
          />
          <img
            className="w-24 h-24 md:w-48 md:h-auto md:rounded-none rounded-full mx-auto"
            src={img5}
            alt="MAPA"
            data-aos="zoom-out-right"
            data-aos-delay="200"
          />
        </div>
      </div>
    </section>
  );
};

export default Cta;
