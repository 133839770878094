import React, { useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Form = () => {
  const [formFields, setFormFields] = useState({
    fullName: "",
    email: "",
    number: "",
    address: "",
    message: "",
  });

  const [emptyState, setEmptyState] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  useEffect(() => {
    const checkEmptyState = Object.values(formFields).some(
      (field) => field === ""
    );
    setEmptyState(checkEmptyState);
  }, [formFields]);

  const sendEmail = (e) => {
    e.preventDefault();

    const templateParams = {
      fullName: formFields.fullName,
      email: formFields.email,
      number: formFields.number,
      address: formFields.address,
      message: formFields.message,
    };

    emailjs
      .send(
        "service_nfs9j3l",
        "template_bzu0uhk",
        templateParams,
        "5ltg9mIb08_gg2BhV"
      )
      .then((response) => {
        console.log("Email sent successfully!", response.text);

        // Display toast message
        toast.success("Your message has been sent successfully!");

        // Clear the form fields after displaying the success message
        setFormFields((prevFields) => ({
          ...prevFields,
          fullName: "",
          email: "",
          number: "",
          address: "",
          message: "",
        }));
      })

      .catch((error) => {
        console.error("Email sending failed!", error.text);
      });
  };
  const handleReset = () => {
    setFormFields({
      fullName: "",
      email: "",
      number: "",
      message: "",
    });
  };

  return (
    <section className="text-gray-600 border-2 body-font section" id="form">
      <div className="container px-5 pt-24 border-2 bg-gray-100 p-1 mx-auto flex flex-wrap justify-start">
        <div className="lg:w-3/5 md:w-1/2 md:pr-16  lg:pr-0 pr-0 items-center">
          {/* Form Heading and Text */}
          <h2
            className="title mb-5 lg:mb-10 text-center max-w-[920px] mx-auto"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            Entre em contato com a nossa equipe de atendimento
          </h2>
          <p
            className="leading-relaxed text-center mb-3 text-[30px] font-medium"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            Estamos <em>prontos</em> para <em>atendê-los!</em>
          </p>
        </div>
        <div
          className="lg:w-2/6 md:w-1/2 bg-gray-200 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <form onSubmit={sendEmail}>
            <div className="relative mb-4">
              <label
                htmlFor="full-name"
                className="leading-7 text-sm text-gray-600"
              >
                Seu Nome
              </label>
              <input
                onChange={handleChange}
                type="text"
                id="full-name"
                name="fullName"
                value={formFields.fullName}
                className="w-full bg-white rounded border border-gray-300 focus:border-yellow-500 focus:ring-2 focus:ring-yellow-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="relative mb-4">
              <label
                htmlFor="email"
                className="leading-7 text-sm text-gray-600"
              >
                Email
              </label>
              <input
                onChange={handleChange}
                type="email"
                id="email"
                name="email"
                value={formFields.email}
                className="w-full bg-white rounded border border-gray-300 focus:border-yellow-500 focus:ring-2 focus:ring-yellow-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="relative mb-4">
              <label
                htmlFor="number"
                className="leading-7 text-sm text-gray-600"
              >
                Telefone
              </label>
              <input
                onChange={handleChange}
                type="tel"
                id="number"
                name="number"
                value={formFields.number}
                required
                className="w-full bg-white rounded border border-gray-300 focus:border-yellow-500 focus:ring-2 focus:ring-yellow-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="relative mb-4">
              <label
                htmlFor="message"
                className="leading-7 text-sm text-gray-600"
              >
                Mensagem
              </label>
              <textarea
                onChange={handleChange}
                id="message"
                name="message"
                value={formFields.message}
                rows="5"
                className="w-full bg-white rounded border border-gray-300 focus:border-yellow-500 focus:ring-2 focus:ring-yellow-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <button
              type="submit"
              className="btn btn-sm bg-accent hover:bg-accentHover w-[102px] text-white"
            >
              Enviar
            </button>
            <button
              type="button"
              className="btn btn-sm bg-gray-300 hover:bg-gray-400 w-[102px] text-gray-700 ml-28 -mt-12 "
              onClick={handleReset}
            >
              Limpar
            </button>
            <ToastContainer />
          </form>
        </div>
      </div>
    </section>
  );
};

export default Form;
