import React from "react";
import "./styles.css";

import logo1 from "../assets/img/lineup/logo-colorido.svg";
import img1 from "../assets/img/lineup/1.png";
import img2 from "../assets/img/lineup/2.png";
import img3 from "../assets/img/lineup/3.png";
import img4 from "../assets/img/lineup/4.png";

const LineupProducts = () => {
  return (
    <div className="content-center" id="LinhadeProdutos">
      <title>AOC Imports</title>
      <section className="border-0 bg-gray-200 ">
        <div className="linhadeprodutos">
          <div className="bg-gray-200 text-center">
            <img
              className="border-amber-500 border-b-[2px] w-48 p-8 h-48 md:w-64 md:h-auto md:rounded-none rounded-full mx-auto"
              src={logo1}
              alt="Logo"
            />
          </div>
        </div>
        <div className="border-2 flex m-px flex-col md:flex-row bg-slate-100 rounded-xl p-5 md:p-0 dark:bg-gray-200 justify-center">
          <div className="pt-6 md:p-8 text-center md:text-left space-y-8 md:w-1/4">
            <img
              className="w-1/2 h-3/4 md:w-48 md:h-auto md:rounded-none rounded-full mx-auto"
              src={img1}
              alt="fone bluetooth"
            />
            <h4 className="text-2xl text-center font-bold text-orange-500">
              Periféricos para eletrônicos
            </h4>
            <ul className="text-lg text-left text-orange-500 list-disc list-inside">
              <li>Capa protetora tablet</li>
              <li>Capa Protetora Smartphone</li>
              <li>Cabo Lightning / HDMI / USB</li>
              <li>Fones de ouvido</li>
              <li>Películas</li>
            </ul>
          </div>
          <div className="pt-6 md:p-8 text-center md:text-left space-y-8 md:w-1/4">
            <img
              className="w-1/2 h-3/4 md:w-48 md:h-auto md:rounded-none rounded-full mx-auto"
              src={img2}
              alt="mascara hospitalar"
            />
            <h4 className="text-2xl  font-bold  text-center text-orange-500">
              Médico, Hospitalar e Estética
            </h4>
            <ul className="text-lg text-left text-orange-500 list-disc list-inside">
              <li>Máscara tripla descartável</li>
              <li>Máscara KN 95</li>
              <li>Oxímetro</li>
              <li>Termômetro</li>
              <li>Luva Nitrílica / Cirúrgica</li>
              <li>Touca descartável</li>
              <li>Avental descartável</li>
              <li>Fios</li>
            </ul>
          </div>
          <div className="pt-6 md:p-8 text-center md:text-left space-y-8 md:w-1/4">
            <img
              className="w-1/2 h-3/4 md:w-48 md:h-auto md:rounded-none rounded-full mx-auto"
              src={img3}
              alt="camiseta"
            />
            <h4 className="text-2xl font-bold text-center text-orange-500">
              Têxtil
            </h4>
            <ul className="text-lg text-left text-orange-500 list-disc list-inside">
              <li>Tecidos</li>
              <li>Tricolines</li>
              <li>Sarja & Brins</li>
              <li>Moda</li>
              <li>Profissional</li>
              <li>Malha</li>
              <li>Microfibra</li>
            </ul>
          </div>
          <div className="pt-6 md:p-8 text-center md:text-left space-y-8 md:w-1/4">
            <img
              className="w-1/2 h-3/4 md:w-48 md:h-auto md:rounded-none rounded-full mx-auto"
              src={img4}
              alt="bolsas"
            />
            <h4 className="text-2xl font-bold text-center text-orange-500">
              Acessórios
            </h4>
            <ul className="text-lg text-left text-orange-500 list-disc list-inside">
              <li>Bolsas de Mão</li>
              <li>Malas</li>
              <li>Mochilas / Pastas</li>
              <li>Carteiras & Brins</li>
              <li>Bolsas de Viagens</li>
              <li>Necessaire</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LineupProducts;
