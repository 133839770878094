import React from 'react';
import { FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa';

const MapComponent = () => {
  return (
    <div className="w-full bg-gray-100 border-2">
      <div className="flex flex-col sm:flex-row">
        <div className="w-full sm:w-1/2 flex items-start justify-center">
          <div className="p-4 w-11/12 sm:w-3/4 h-80">
            <iframe
              className="w-full h-full"
              title="AOC Imports - Matriz Joinville"
              src="https://maps.google.com/maps?q=AOC%20Imports%20-%20Matriz%20Joinville%20-%20Importadora%20e%20Exportadora%2C%20Centro%20Empresarial%20Everest%20-%20Rua%20Dona%20Francisca%20-%20Sagua%C3%A7u%2C%20Joinville%20-%20SC&amp;t=m&amp;z=11&amp;output=embed&amp;iwloc=near"
            ></iframe>
          </div>
        </div>
        <div className="w-full sm:w-1/2 bg-white p-8 flex items-start justify-center">
          <div>
            <h2 className="text-3xl text-orange-600 font-bold mb-6">Matriz</h2>
            <div className="flex items-center mb-4">
              <FaMapMarkerAlt className="text-gray-500 text-xl mr-4" />
              <p className="text-lg text-gray-700">
                Rua Dona Francisca, nº 1113, Centro
                <br />
                Empresarial Everest, Sala 510,
                <br />
                89221-006, Saguaçu, Joinville, SC
              </p>
            </div>
            <div className="flex items-center">
              <FaPhoneAlt className="text-gray-500 text-xl mr-4" />
              <p className="text-lg text-gray-700">(47) 3422-6754</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row">
        <div className="w-full sm:w-1/2 flex items-start justify-center">
          <div className="p-4 w-11/12 sm:w-3/4 h-80">
            <iframe
              className="w-full h-full"
              title="AOC Imports - Filial Itajaí"
              src="https://maps.google.com/maps?q=AOC%20Imports%20-%20Filial%20Itaja%C3%AD%20-%20Importadora%20e%20Exportadora&amp;t=m&amp;z=11&amp;output=embed&amp;iwloc=near"
            ></iframe>
          </div>
        </div>
        <div className="w-full sm:w-1/2 bg-white p-8 flex items-start justify-center">
          <div className='text-left'>
            <h2 className="text-3xl text-orange-600 font-bold mb-6">Filial</h2>
            <div className="flex items-center mb-4">
              <FaMapMarkerAlt className="text-gray-500 text-xl mr-4" />
              <p className="text-lg text-gray-700">
                Rua Dr. Nereu Ramos, nº 197,
                <br />
                Ed. Seixas Business Tower, Sala 306,
                <br />
                88301-215, Centro, Itajaí, SC
              </p>
            </div>
            <div className="flex items-center">
              <FaPhoneAlt className="text-gray-500 text-xl mr-4" />
              <p className="text-lg text-gray-700"> (47) 3515-2105 </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapComponent;
