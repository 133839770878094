import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./Footer"

export default function Login(props) {
 const [errorMessage, setErrorMessage] = useState("");
  const handleEntrar = (event) => {
    event.preventDefault();
    setErrorMessage(
      
      "Infelizmente não foi possível entrar agora. Tente novamente mais tarde."
    );
    toast.error("Infelizmente não foi possível entrar agora. Tente novamente mais tarde.");
    
  };


  return (
    <div className="Auth-form-container  border-2" id="Login">
        <ToastContainer />
      <form className="Auth-form justify-center">
        <div className="border-4 m-4 pl-12 w-96 Auth-form-content">
          <h3 className="Auth-form-title font-bold text-2xl text-orange-500 pt-2 text-left ml-16 mb-6">Login</h3>
          <div className="form-group mt-3">
            <label className="block">CNPJ</label>
            <input
              type="tel"
              min="14"
              max="14"
              className="form-control mt-1 rounded-md px-4 py-2 border border-gray-300"
              placeholder="Insira seu CNPJ"
            />
          </div>
          <div className="form-group mt-3">
            <label className="block">Senha</label>
            <input
              type="password"
              className="form-control mt-1 rounded-md px-4 py-2 border border-gray-300"
              placeholder="Insira sua Senha"
            />
          </div>
          <div className="flex gap-2 mt-3">
            <button
              onClick={handleEntrar}
              type="submit"
              className="btn btn-primary bg-orange-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded"
            >
              Entrar
            </button>
            <a className="btn btn-primary w-24 h-auto font-bold bg-orange-500 hover:bg-orange-400 text-white" href="/Cadastro">Cadastrar</a>
            <br />
            <p className="forgot-password text-right mt-2">
              Esqueceu a <a href="#">senha?</a>
            </p>
          </div>
          <br />
          <a className="btn btn-primary w-24 h-auto bg-orange-500 hover:bg-orange-400 text-white" href="/">
            Voltar
          </a>
          {errorMessage && (
            <div className="text-red-500 mt-2">{errorMessage}</div>
          )}
        </div>
      </form>
     
    </div>
    
  );
}
