import React, { useRef } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Pdf from "../Documents/catalogo.pdf";

import "./styles.css";
// import required modules
/* import { Autoplay, Pagination, Navigation } from "swiper";*/

function Swipe() {
  /*const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty("--progress", 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };*/
  return (
    <>
      <section className="border-2  border-gray-100 " >
        <div className=" mb-4 m-2 text-center text-2xl font-extrabold leading-none tracking-tight text-orange-600 md:text-5xl lg:text-6xl dark:text-orange-600" >
        <a className="underline decoration-orange-600 md:decoration-orange-400"  href = {Pdf} target = "_blank">Confira nosso Catálogo clicando aqui</a>
        </div>
        
      </section>
    </>
  );
}
export default Swipe;